import { css } from "emotion";

function containerStyle({ reorder }) {
  return css`
    .category-actions {
      padding: 12px 16px;
      width: 64px;
      height: 56px;
      box-sizing: border-box;
    }
  `;
}

export { containerStyle };
