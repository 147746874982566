import React from "react";
import { MdMoreVert } from "react-icons/md";
import { containerStyles, iconButtonStyles } from "./Header.styles";

function Header({ onMenuIconClick, userPhotoURL, userLoggedIn, children }) {
  return (
    <div className={containerStyles}>
      <div className={iconButtonStyles} onClick={onMenuIconClick}>
        <MdMoreVert />
      </div>
      <div className="tools">{children}</div>
    </div>
  );
}

export { Header };
