import firebase from "utils/firebase";

export const data = {
  async get(ref) {
    const snapshot = await ref.once("value");
    return snapshot.val();
  },
  async delete(ref) {
    await ref.set(null, error => {
      if (error) {
        console.log(error);
      }
    });
  }
};
export const dictionary = {
  async create(ref, obj) {
    const newRef = ref.push();
    await newRef.set({
      id: newRef.key,
      createdOn: firebase.database.ServerValue.TIMESTAMP,
      updatedOn: firebase.database.ServerValue.TIMESTAMP,
      ...obj
    });
    return newRef.key;
  },
  async get(ref) {
    const value = await data.get(ref);
    return value || {};
  },
  async update(ref, ...values) {
    const updates = values.reduce(
      (acc, i) => ({
        ...acc,
        [i.id]: { ...i, updatedOn: firebase.database.ServerValue.TIMESTAMP }
      }),
      {}
    );
    await ref.update(updates);
  }
};

export const array = {
  async deleteValue(ref, value) {
    const items = await this.get(ref);
    await ref.set(items.filter(i => i !== value), error => {
      if (error) {
        console.log(error);
      }
    });
  },
  async get(ref) {
    const value = await data.get(ref);
    return value || [];
  },
  async unshift(ref, value) {
    const items = await this.get(ref);
    items.unshift(value);
    await ref.set(items, error => {
      if (error) {
        console.log(error);
      }
    });
  },
  async update(ref, value) {
    await ref.set(value, error => {
      if (error) {
        console.log(error);
      }
    });
  }
};

export const addDatabaseEventListener = dbRef => callback =>
  dbRef.on("value", callback);
