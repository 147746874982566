import React, { useState, useEffect, useCallback } from "react";
import { containerStyle } from "./Snackbar.styles";

function Snackbar({ messageText, actionLabel }) {
  const [visible, setVisible] = useState(false);
  const [handleAction, setHandleAction] = useState(() => {});

  const handleActionButtonClick = useCallback(() => {
    handleAction();
    setVisible(false);
  }, [handleAction, setVisible]);

  useEffect(() => {
    const handleDisplaySnackbar = e => {
      setVisible(true);
      setHandleAction(() => e.detail.handleAction);
    };
    document.addEventListener("display-snackbar", handleDisplaySnackbar);

    return () => {
      document.removeEventListener("display-snackbar", handleDisplaySnackbar);
    };
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div className={containerStyle}>
      <span className="message">{messageText}</span>
      <button onClick={handleActionButtonClick} className="action">
        {actionLabel}
      </button>
    </div>
  );
}

export { Snackbar };
