import firebase from "utils/firebase";

import { userInfoGetSuccess, userStatusSet } from "user/actions";
import { mapDispatchToDatabaseEvents as mapDispatchToCategoriesDatabaseEvents } from "categories/actions";
import { mapDispatchToDatabaseEvents as mapDispatchToItemsDatabaseEvents } from "items/actions";
import { attachItemsDatabaseEvents } from "items/db";
import { USER_STATUS } from "user/reducers";
export const signInWithGoogle = () =>
  firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider());

export const signInWithFacebook = () =>
  firebase.auth().signInWithRedirect(new firebase.auth.FacebookAuthProvider());

export const unlink = async providerId => {
  const user = firebase.auth().currentUser;
  if (!user) return;

  await user.unlink(providerId);
};

export const addAuthStateChangedEventListener = ({ store, history }) => {
  firebase.auth().onAuthStateChanged(async user => {
    if (user) {
      store.dispatch(userInfoGetSuccess(user));
      store.dispatch(userStatusSet(USER_STATUS.SIGNED_IN));
      attachItemsDatabaseEvents(user.uid);
      mapDispatchToCategoriesDatabaseEvents(store, user.uid);
      mapDispatchToItemsDatabaseEvents(store, user.uid);
      history.push("/categories");
    } else {
      store.dispatch(userStatusSet(USER_STATUS.SIGNED_OUT));
    }
  });
};
