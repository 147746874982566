import React, { useCallback, useState } from "react";
import { containerStyles } from "./ItemMiniForm.styles";
import { MdAdd } from "react-icons/md";

function ItemMiniForm({ backgroundColor, onSubmit }) {
  const [title, setTitle] = useState("");

  const handleTitleInputChange = useCallback(function handleTitleInputChange({
    target
  }) {
    setTitle(target.value);
  },
  []);

  const handleSubmit = useCallback(
    function handleSubmit(e) {
      e.preventDefault();
      onSubmit(title);
      setTitle("");
    },
    [title, onSubmit]
  );

  return (
    <form
      data-testid="item-mini-form"
      className={containerStyles({ backgroundColor })}
      onSubmit={handleSubmit}
    >
      <input
        value={title}
        required={true}
        onChange={handleTitleInputChange}
        name="title"
        type="text"
        placeholder="Add an item"
      />
      <button type="submit">
        <MdAdd />
      </button>
    </form>
  );
}

export { ItemMiniForm };
