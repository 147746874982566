import { combineReducers } from "redux";
import * as fromCategories from "categories/reducers";
import user, * as fromUser from "user/reducers";
import items, * as fromItems from "items/reducers";

const { categories } = fromCategories;

const appReducer = combineReducers({
  categories,
  items,
  user
});

const rootReducer = (state, action) => {
  if (action.type === "APP_RESET") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

export const getUserInfo = state => fromUser.getUserInfo(state.user);
export const userStatus = state => fromUser.getUserStatus(state.user);
export const getUserPhotoURL = state => fromUser.getPhotoURL(state.user);
export const userLoggedIn = state => fromUser.userLoggedIn(state.user);
export const getCategories = state =>
  state.categories.allIds.map(id => state.categories.byId[id]).filter(Boolean);
export const getCategoryIds = state => state.categories.allIds;
export const getCategory = (state, categoryId) =>
  fromCategories.getCategory(state.categories, categoryId);
export const getItems = (state, filters) =>
  fromItems.getItems(state.items, filters);
export const getItem = (state, uid) => fromItems.getItem(state.items, uid);
export const getItemsByCategory = (state, categoryId) =>
  fromItems.getItemsByCategory(state.items, categoryId);
