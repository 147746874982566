import React, { useCallback } from "react";
import { css } from "emotion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Category } from "categories/components/Category";
import { withRouter } from "react-router-dom";

const noCategoriesContainerStyles = css`
  font-size: 24px;
  color: white;
  padding: 16px;
`;

function CategoryList({
  categories,
  history,
  orderCategories,
  isDragDisabled
}) {
  const handleDragEnd = useCallback(
    function handleDragEnd(result) {
      const {
        source: { index: oldIndex },
        destination: { index: newIndex }
      } = result;
      orderCategories({ oldIndex, newIndex });
    },
    [orderCategories]
  );

  const handleClickOnCategory = useCallback(
    function handleClickOnCategory({ categoryId }) {
      history.push(`/categories/${categoryId}`);
    },
    [history]
  );

  function renderDraggableCategory(category, index) {
    return (
      <Draggable
        key={category.id}
        draggableId={category.id}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {provided => (
          <li ref={provided.innerRef} {...provided.draggableProps}>
            <Category
              innerDragHandleProps={provided.dragHandleProps}
              category={category}
              isDragDisabled={isDragDisabled}
              onClick={handleClickOnCategory.bind(this, {
                categoryId: category.id
              })}
            />
          </li>
        )}
      </Draggable>
    );
  }

  return (
    <>
      {categories.length === 0 && (
        <div className={noCategoriesContainerStyles}>
          No categories yet, create your first with the pink button
        </div>
      )}
      {categories.length > 0 && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={"categories"}>
            {droppableProvided => (
              <div ref={droppableProvided.innerRef}>
                <ul data-testid="categories-list">
                  {categories.map(renderDraggableCategory)}
                </ul>
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
}

export default withRouter(CategoryList);
