import React from "react";

function Logo({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <title>background</title>
        <rect
          fill="#212121"
          id="canvas_background"
          width="100%"
          height="100%"
          y="-1"
          x="-1"
        />
      </g>

      <g fill="#fff">
        <title>Layer 1</title>
        <path
          d="m185.66665,234.70001l-46.88885,0l0,42.6l46.88888,0l0.33333,-42.6l-0.33336,0zm46.55557,0l46.88888,42.6l-46.88888,-42.6zm140.66667,0l-46.88889,0l0,42.6l46.88889,0l0,-42.6zm46.88888,-149.10001l-23.44445,0l0,-42.6l-46.88888,0l0,42.6l-187.55557,0l0,-42.6l-46.88888,0l0,42.6l-23.44445,0c-26.02333,0 -46.65445,19.17 -46.65445,42.6l-0.23445,298.2c0,23.43 20.86555,42.6 46.88888,42.6l328.22223,0c25.78888,0 46.88888,-19.17 46.88888,-42.6l0,-298.2c0,-23.43 -21.1,-42.6 -46.88888,-42.6l0.00002,0zm0,340.8l-328.22223,0l0,-234.30001l328.22223,0l0,234.30001z"
          id="svg_1"
          stroke="null"
        />
      </g>
      <g>
        <title>Layer 2</title>
        <path
          transform="rotate(10 261.0060119628909,347.00604248046875) "
          stroke="null"
          d="m238.68608,373.72344l-29.04027,-28.75135l-9.88901,9.72168l38.92929,38.54198l83.56913,-82.7377l-9.81937,-9.72168l-73.74976,72.94707l-0.00001,0z"
          id="svg_2"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export { Logo };
