import firebase from "utils/firebase";
import { array, data, dictionary } from "utils/db";

export const refs = {
  byId(userId, id = "") {
    return firebase.database().ref(`/data/items/${userId}/byId/${id}`);
  },
  byCategoryId(userId, categoryId, filter = "") {
    return firebase
      .database()
      .ref(
        `/data/items/${userId}/byCategoryId/${
          categoryId ? `${categoryId}/${filter}` : ""
        }`
      );
  }
};

export const Items = {
  async create(userId, newItem) {
    const id = await dictionary.create(refs.byId(userId), newItem);
    return await array.unshift(
      refs.byCategoryId(userId, newItem.categoryId, "active"),
      id
    );
  },
  async delete(userId, ...ids) {
    const updates = ids.reduce((acc, curr) => ({ ...acc, [curr]: null }), {});
    return await refs.byId(userId).update(updates);
  },
  async deleteByCategory(userId, categoryId) {
    const result = await Promise.all([
      array.get(refs.byCategoryId(userId, categoryId, "active")),
      array.get(refs.byCategoryId(userId, categoryId, "completed"))
    ]);
    const itemIds = result.reduce((acc, curr) => [...acc, ...curr], []);
    await this.delete(userId, ...itemIds);
    return await data.delete(refs.byCategoryId(userId, categoryId));
  },
  async update(userId, ...updatedItems) {
    return await dictionary.update(refs.byId(userId), ...updatedItems);
  },
  async updateByCategoryIds(userId, categoryId, filter, ids) {
    await array.update(refs.byCategoryId(userId, categoryId, filter), ids);
  }
};

// Move to server side
export const attachItemsDatabaseEvents = userId => {
  refs.byId(userId).on("child_removed", async snapshot => {
    const { categoryId, completed, id } = snapshot.val();
    const filter = getFilterFromItemCompleted(completed);
    const ref = refs.byCategoryId(userId, categoryId, filter);
    await array.deleteValue(ref, id);
  });
  refs.byId(userId).on("child_changed", async snapshot => {
    const item = snapshot.val();
    await updateByCategoryIdData(userId, item.id, item);
  });
};

export const getFilterFromItemCompleted = completed =>
  completed ? "completed" : "active";

/**
 * Database Event handlers
 */
const updateByCategoryIdData = async (userId, itemId, updatedItem) => {
  const { categoryId, completed: isCompletedItem } = updatedItem;
  const isActiveItem = !isCompletedItem;
  const activeByCategoryRef = refs.byCategoryId(userId, categoryId, "active");
  const completedByCategoryRef = refs.byCategoryId(
    userId,
    categoryId,
    "completed"
  );
  const [activeByCategory, completedByCategory] = await Promise.all([
    array.get(activeByCategoryRef),
    array.get(completedByCategoryRef)
  ]);

  if (isCompletedItem && completedByCategory.includes(itemId)) {
    return;
  }
  if (isActiveItem && activeByCategory.includes(itemId)) {
    return;
  }
  if (isCompletedItem) {
    await array.unshift(completedByCategoryRef, itemId);
    await array.deleteValue(activeByCategoryRef, itemId);
  }
  if (isActiveItem) {
    await array.unshift(activeByCategoryRef, itemId);
    await array.deleteValue(completedByCategoryRef, itemId);
  }
};
