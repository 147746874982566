export const USER_STATUS = {
  UNKNOWN: 0,
  SIGNED_IN: 1,
  SIGNED_OUT: 2
};

const initialState = {
  info: null,
  status: USER_STATUS.UNKNOWN
};

const user = (state = initialState, action = { type: "" }) => {
  switch (action.type) {
    case "USER_INFO_GET_SUCCESS":
      return {
        ...state,
        info: action.payload
      };
    case "USER_STATUS_SET":
      return {
        ...state,
        status: action.status
      };
    default:
      return state;
  }
};

export default user;

export const getUserInfo = state => state.info;
export const getUserStatus = state => state.status;
export const getPhotoURL = state => (state.info ? state.info.photoURL : null);
export const userLoggedIn = state => state.info !== null;
