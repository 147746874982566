import React, { useCallback } from "react";
import { Item } from "items/components/Item";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { orderItems, updateItem } from "items/actions";
import { withRouter } from "react-router-dom";

function CategoryItems({
  items = [],
  category,
  history,
  isDragDisabled,
  orderItems: orderItemsProp,
  updateItem
}) {
  const handleDragEnd = useCallback(
    function handleDragEnd(result) {
      const {
        source: { index: oldIndex },
        destination: { index: newIndex }
      } = result;
      orderItemsProp({ oldIndex, newIndex });
    },
    [orderItemsProp]
  );

  const toggleItemStatus = useCallback(
    function toggleItemStatus({ itemId, item }) {
      updateItem(itemId, {
        completed: !item.completed
      });
    },
    [updateItem]
  );

  const handleItemClick = useCallback(
    function handleItemClick({ itemId }) {
      history.push(`/items/${itemId}`);
    },
    [history]
  );

  function renderDraggableItems(item, index) {
    return (
      <Draggable
        key={item.id}
        draggableId={item.id}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {function renderDraggableChildren(provided) {
          return (
            <div ref={provided.innerRef} {...provided.draggableProps}>
              <Item
                innerDragHandleProps={provided.dragHandleProps}
                item={item}
                onClick={handleItemClick.bind(this, {
                  itemId: item.id
                })}
                backgroundColor={category.color}
                isDragDisabled={isDragDisabled}
                overlayOpacity={getOverlayOpacity(index, items.length)}
                onItemStatusToggle={toggleItemStatus.bind(this, {
                  itemId: item.id,
                  item
                })}
              />
            </div>
          );
        }}
      </Draggable>
    );
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={"items"}>
        {function renderDroppableChildren(droppableProvided) {
          return (
            <ul data-testid="item-list" ref={droppableProvided.innerRef}>
              {items.map(renderDraggableItems)}
            </ul>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}

const getOverlayOpacity = function getOverlayOpacity(index, length) {
  const minOpacity = 0.05;
  const maxOpacity = 0.8;
  const opacityDistance = maxOpacity - minOpacity;
  const perItemOpacity = opacityDistance / (length > 10 ? length : 10);

  return minOpacity + perItemOpacity * index;
};

export default connect(
  undefined,
  (dispatch, ownProps) => ({
    updateItem: (...params) => dispatch(updateItem(...params)),
    orderItems: (...params) =>
      dispatch(
        orderItems({
          categoryId: ownProps.categoryId,
          filter: ownProps.itemsStatus
        })(...params)
      )
  })
)(withRouter(React.memo(CategoryItems)));
