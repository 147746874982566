const ItemFactory = ({
  completed = false,
  description = "",
  title = "untitled",
  ...rest
}) => {
  return {
    completed,
    description,
    title: title === "" ? "untitled" : title,
    ...rest
  };
};

export default ItemFactory;
