import { css } from "emotion";

export const loadingIcon = css`
  fill: #fff;
  width: 76px;
  height: 76px;
  animation: rotation 2s infinite linear;
`;

export const logoStyle = css`
  fill: #fff;
  width: 76px;
  height: 76px;
`;

export const outerContainerStyles = css`
  height: 100vh;
  display: flex;
`;

export const innerContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
  text-align: center;
  color: white;

  .button {
    background: white;
    padding: 8px;
    width: 100%;
    margin: 0 16px 16px 16px;
    border-radius: 5px;
    display: flex;
    height: 42px;
    align-items: center;
    max-width: 250px;
    justify-content: center;

    img {
      height: 24px;
      margin: 0 8px 2px 4px;
    }
    &.facebook {
      background: #4267b2;
      color: white;
    }
  }

  .error-message {
    color: red;
    margin-bottom: 16px;
    text-align: left;
    width: 240px;
  }
  .validation-message {
    margin-bottom: 16px;
    width: 240px;
    text-align: left;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  input[type="email"] {
    background: transparent;
    display: block;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 8px;
    width: 80%;
    max-width: 250px;
    height: 32px;
    border: none;
    border-bottom: 1px solid #aaa;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
  }

  input[type="email"]:focus {
    outline: none;
    border-bottom: 2px solid #fff;
  }
`;
