import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import firebase from "utils/firebase";
import { unlink } from "user/auth";
import { userInfoGetSuccess } from "user/actions";
import { container } from "./Settings.styles";

const ProviderTypes = {
  Facebook: "facebook.com",
  Google: "google.com",
  Email: "password"
};

const Settings = ({ providerIds, dispatch, userInfo }) => {
  const [errorMessage, setErrorMessage] = useState();
  const hasMoreThanOneProviderIds = providerIds.length > 1;

  const unlinkProviderType = useCallback(
    async function unlinkProviderType(providerType) {
      try {
        await unlink(providerType);
        dispatch(userInfoGetSuccess(firebase.auth().currentUser));
      } catch (error) {
        setErrorMessage(error.message);
      }
    },
    [dispatch]
  );

  const linkProviderType = useCallback(
    async providerType => {
      let provider;
      switch (providerType) {
        case ProviderTypes.Facebook:
          provider = new firebase.auth.FacebookAuthProvider();
          break;
        case ProviderTypes.Google:
          provider = new firebase.auth.GoogleAuthProvider();
          break;
        default:
      }

      if (provider) {
        const { user } = await firebase
          .auth()
          .currentUser.linkWithPopup(provider);
        dispatch(userInfoGetSuccess(user));
      }
    },
    [dispatch]
  );

  const handleLinkToGoogle = useCallback(
    function handleLinkToGoogle() {
      linkProviderType(ProviderTypes.Google);
    },
    [linkProviderType]
  );
  const handleLinkToFacebook = useCallback(
    function handleLinkToFacebook() {
      linkProviderType(ProviderTypes.Facebook);
    },
    [linkProviderType]
  );
  const handleUnlinkToGoogle = useCallback(
    function handleUnlinkToGoogle() {
      unlinkProviderType(ProviderTypes.Google);
    },
    [unlinkProviderType]
  );
  const handleUnlinkToFacebook = useCallback(
    function handleUnlinkToFacebook() {
      unlinkProviderType(ProviderTypes.Facebook);
    },
    [unlinkProviderType]
  );

  if (providerIds === null) return null;

  return (
    <div className={container}>
      <h1>Settings</h1>

      <h3>Account</h3>
      <div className="account-method">
        {errorMessage && <span className="error-message">{errorMessage}</span>}
        <div>
          {providerIds.includes(ProviderTypes.Email) ? (
            <span>
              Email Verified: <i>{userInfo.email}</i>
            </span>
          ) : (
            "Verify your sign in address by using it to sign in from the login page."
          )}
        </div>
      </div>
      <div className="account-method">
        <div>Google Auth</div>
        {!providerIds.includes(ProviderTypes.Google) && (
          <button className={"button"} onClick={handleLinkToGoogle}>
            <img src="/google-logo.svg" alt="google-logo" />
            <span>Sign in</span>
          </button>
        )}
        {providerIds.includes(ProviderTypes.Google) && (
          <button
            disabled={!hasMoreThanOneProviderIds}
            className={"button"}
            onClick={handleUnlinkToGoogle}
          >
            <img src="/google-logo.svg" alt="google-logo" />
            <span>Unlink</span>
          </button>
        )}
      </div>
      <div className="account-method">
        <div>Facebook Auth</div>
        {!providerIds.includes(ProviderTypes.Facebook) && (
          <button className="button facebook" onClick={handleLinkToFacebook}>
            <img src="/facebook-logo.svg" alt="facebook-logo" />
            <span>Sign in</span>
          </button>
        )}
        {providerIds.includes(ProviderTypes.Facebook) && (
          <button
            disabled={!hasMoreThanOneProviderIds}
            className="button facebook"
            onClick={handleUnlinkToFacebook}
          >
            <img src="/facebook-logo.svg" alt="facebook-logo" />
            <span>Unlink</span>
          </button>
        )}
      </div>
    </div>
  );
};

function getProviderId(p) {
  return p.providerId;
}

export default connect(state => ({
  userInfo: state.user.info,
  providerIds:
    state.user.info && state.user.info.providerData.map(getProviderId)
}))(Settings);
