import React, { useState, useCallback, useEffect } from "react";
import { containerStyles } from "./DropMenu.styles";

function DropMenuItems({ onClick: handleClick, children }) {
  return <div onClick={handleClick}>{children}</div>;
}

function ConfirmationPanel({
  actionLabel,
  onConfirm: handleConfirm,
  onCancel: handleCancel
}) {
  return (
    <div>
      {`${actionLabel}: Are you sure?`}
      <button className={"confirmButton"} onClick={handleConfirm}>
        Yes
      </button>
      <button className={"cancelButton"} onClick={handleCancel}>
        No
      </button>
    </div>
  );
}

function DropMenu() {
  const [items, setItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirm, setConfirm] = useState();

  const toggleMenu = useCallback(
    function toggleMenu(e) {
      setItems(e.detail ? e.detail.items : items);
      setVisible(!visible);
      setConfirm(false);
    },
    [visible, items]
  );
  useEffect(() => {
    document.addEventListener("toggleMenu", toggleMenu);

    return () => {
      document.removeEventListener("toggleMenu", toggleMenu);
    };
  }, [toggleMenu]);

  return (
    <div className={containerStyles}>
      <div
        onClick={toggleMenu}
        className={`overlay ${visible ? "display" : ""}
        `}
      />
      <div className={`menu ${visible ? "visible" : ""}`}>
        {confirm && (
          <ConfirmationPanel
            actionLabel={confirm.actionLabel}
            onConfirm={e => {
              confirm.callback();
              toggleMenu(e);
            }}
            onCancel={toggleMenu}
          />
        )}
        {items &&
          !confirm &&
          items.map((i, index) => (
            <DropMenuItems
              key={index}
              onClick={e => {
                if (i.shouldConfirm) {
                  setConfirm({ actionLabel: i.label, callback: i.onClick });
                } else {
                  toggleMenu(e);
                  i.onClick();
                }
              }}
            >
              {i.label}
            </DropMenuItems>
          ))}
      </div>
    </div>
  );
}

export { DropMenu };
