import { css } from "emotion";

const userPhoto = css`
  margin-right: 16px;
  align-items: center;
  height: 100%;
  display: flex;

  img {
    border-radius: 38px;
    width: 38px;
    margin-top: 5px;
  }
`;

const reorderIcon = ({ reorder }) => css`
  fill: ${reorder ? "white" : "rgba(255, 255, 255, 0.5)"};
  width: 32px;
  height: 32px;
`;

export { userPhoto, reorderIcon };
