import React, { useState, useCallback } from "react";
import { signInWithGoogle, signInWithFacebook } from "../../auth.js";
import {
  innerContainerStyles,
  outerContainerStyles,
  logoStyle,
  loadingIcon
} from "./Login.styles";
import { marginBottom } from "shared/styles/spacing";
import firebase from "utils/firebase";
import { MdSettings } from "react-icons/md";
import { Logo } from "components/Logo";

function Login({ location, history }) {
  const [email, setEmail] = useState("");
  const [loadingRedirectResult, setLoadingRedirectResult] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [socialAuthErrorMessage, setSocialAuthErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailValidationMessage, setEmailValidationMessage] = useState("");

  const handleEmailInputChange = useCallback(
    function handleEmailInputChange(e) {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  if (localStorage.getItem("isRedirect") === "1") {
    setLoadingRedirectResult(true);
    firebase
      .auth()
      .getRedirectResult()
      .catch(error => {
        setLoadingRedirectResult(false);
        setSocialAuthErrorMessage(error.message);
      });

    localStorage.setItem("isRedirect", "0");
  }

  const handleFacebookAuth = useCallback(async function handleFacebookAuth() {
    try {
      localStorage.setItem("isRedirect", "1");
      await signInWithFacebook();
    } catch (error) {
      setSocialAuthErrorMessage(error.message);
    }
  }, []);

  const handleGoogleAuth = useCallback(async function handleGoogleAuth() {
    try {
      localStorage.setItem("isRedirect", "1");
      await signInWithGoogle();
    } catch (error) {
      setSocialAuthErrorMessage(error.message);
    }
  }, []);

  const handleSubmit = useCallback(
    async function handleSubmit(e) {
      e.preventDefault();

      try {
        if (email === "") {
          throw new Error("Enter your email above");
        }

        await firebase.auth().sendSignInLinkToEmail(email, {
          url: `${window.location.origin}/login?emailSignIn`,
          // This must be true.
          handleCodeInApp: true
        });
        setEmailErrorMessage("");
        setEmailValidationMessage(
          "You will receive an email shortly, click the link in the email to sign in"
        );
        window.localStorage.setItem("emailForSignIn", email);
      } catch (error) {
        setEmailErrorMessage(error.message);
      }
    },
    [email]
  );

  const handleSignInEmailLink = useCallback(
    async function handleSignInEmailLink(e, { localStorageEmail } = {}) {
      if (e) {
        e.preventDefault();
      }
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        const signInEmail = localStorageEmail ? localStorageEmail : email;
        if (!signInEmail) {
          setLoadingRedirectResult(false);
          setVerifyEmail(true);
          setEmailErrorMessage("");
          setEmailValidationMessage(
            "Please provide your email for confirmation"
          );
          return;
        }
        try {
          await firebase
            .auth()
            .signInWithEmailLink(signInEmail, window.location.href);
          window.localStorage.removeItem("emailForSignIn");
        } catch (error) {
          setLoadingRedirectResult(false);
          setEmailValidationMessage("");
          setEmailErrorMessage(error.message);
        }
      } else {
        history.push("/");
      }
    },
    [email, history]
  );

  const isEmailLink = location.search.includes("signIn");

  if (isEmailLink && !loadingRedirectResult && !verifyEmail) {
    setLoadingRedirectResult(true);
    const localStorageEmail = window.localStorage.getItem("emailForSignIn");
    handleSignInEmailLink(undefined, { localStorageEmail });
  }

  if (loadingRedirectResult) {
    return (
      <div className={outerContainerStyles}>
        <div className={innerContainerStyles}>
          <MdSettings className={loadingIcon} />
        </div>
      </div>
    );
  }

  return (
    <div className={outerContainerStyles}>
      <div className={innerContainerStyles}>
        {!isEmailLink && (
          <>
            <div className={marginBottom(4)}>
              <Logo className={logoStyle} />
            </div>
            <button className={"button"} onClick={handleGoogleAuth}>
              <img src="/google-logo.svg" alt="google-logo" />
              <span>Continue with Google</span>
            </button>
            <button className="button facebook" onClick={handleFacebookAuth}>
              <img src="/facebook-logo.svg" alt="facebook-logo" />
              <span>Continue with Facebook</span>
            </button>
          </>
        )}
        {socialAuthErrorMessage && (
          <div className="error-message">{socialAuthErrorMessage}</div>
        )}
        <form onSubmit={verifyEmail ? handleSignInEmailLink : handleSubmit}>
          <div className={marginBottom(2)}>
            <span>
              {verifyEmail
                ? "Confirm your email"
                : "or Sign in with your email"}
            </span>
          </div>
          <input
            name="email"
            value={email}
            type="email"
            placeholder="E-mail"
            onChange={handleEmailInputChange}
          />
          {emailErrorMessage && (
            <div className="error-message">{emailErrorMessage}</div>
          )}

          {emailValidationMessage && (
            <div className={"validation-message"}>{emailValidationMessage}</div>
          )}
          <button type="submit" className={"button"}>
            Sign in
          </button>
        </form>
      </div>
    </div>
  );
}

export { Login };
