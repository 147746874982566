import { mockedDb } from "./db";

const ProviderTypes = {
  Facebook: "facebook.com",
  Google: "google.com",
  Email: "password"
};

const providerDataGoogle = {
  uid: "105502748622412105583",
  displayName: "Maxime Letourneur",
  photoURL: "http://localhost:3000/logo.png",
  email: "maxime.letourneur@gmail.com",
  phoneNumber: null,
  providerId: "google.com"
};
const providerDataFacebook = {
  uid: "10155270372289851",
  displayName: "Max Ltrnr",
  photoURL: "http://localhost:3000/logo.png",
  email: "maxime.letourneur@gmail.com",
  phoneNumber: null,
  providerId: "facebook.com"
};

const providerDataPassword = {
  uid: "maxime.letourneur@gmail.com",
  displayName: "Maxime Letourneur",
  photoURL: "http://localhost:3000/logo.png",
  email: "maxime.letourneur@gmail.com",
  phoneNumber: null,
  providerId: "password"
};

const user = providerData => ({
  displayName: "Maxime Letourneur",
  email: "maxime.letourneur@gmail.com",
  photoURL: "http://localhost:3000/logo.png",
  uid: "WePtVenhrlO2xwr6ryT3tPJlo2b2",
  providerData
});

let currentUserProviders = [
  providerDataGoogle,
  providerDataFacebook,
  providerDataPassword
];

// @ts-ignore
window.databaseInstance = undefined;

const pathValueHandlerMap = new Map();
const pathChildChangedHandlerMap = new Map();

function database() {
  // @ts-ignore
  if (!window.databaseInstance) {
    // @ts-ignore
    window.databaseInstance = {
      mockedDb,
      ref(path) {
        let pathKeys = path.split("/");
        pathKeys = pathKeys.filter(e => !!e);

        const dataValue = pathKeys.reduce((acc, curr) => {
          return acc ? acc[curr] : undefined;
        }, this.mockedDb);

        return {
          key: undefined,
          push() {
            const randomId = `${Math.random() * 100000}`;
            const newRef = database().ref(`${path}${randomId}`);
            newRef.key = randomId;
            return newRef;
          },
          once() {
            return {
              val() {
                // @ts-ignore
                const db = JSON.parse(
                  JSON.stringify(window.databaseInstance.mockedDb)
                );
                return pathKeys.reduce((acc, curr) => {
                  return acc ? acc[curr] : undefined;
                }, db);
              }
            };
          },
          on(event, callback) {
            if (event === "value") {
              pathValueHandlerMap.set(path, callback);
              callback({
                val() {
                  return dataValue;
                }
              });
            }

            if (event === "child_changed") {
              pathChildChangedHandlerMap.set(path, callback);
            }
          },
          set(update) {
            const isArray = Array.isArray(update);
            let deleted;
            let deletedParent;

            for (let i = 0, current, previous; i < pathKeys.length; i++) {
              if (!current) {
                // @ts-ignore
                current = window.databaseInstance.mockedDb[pathKeys[i]];
              } else {
                previous = current;
                current = current[pathKeys[i]];
                if (current === undefined) {
                  previous[pathKeys[i]] =
                    isArray && i === pathKeys.length - 1 ? [] : {};
                  current = previous[pathKeys[i]];
                }
              }
              if (i === pathKeys.length - 1) {
                if (isArray) {
                  current.length = 0;
                  if (update.length > 0) {
                    current.push(...update);
                  }
                } else {
                  if (update === null) {
                    deleted = Object.assign({}, previous[pathKeys[i]]);
                    previous[pathKeys[i]] = undefined;
                    deletedParent = previous;
                  } else {
                    current = Object.assign(current, update);
                  }
                }
              }
            }

            let callback;
            let callbackPathKeys;

            // @ts-ignore
            for (let pathKey of pathValueHandlerMap.keys()) {
              if (path.includes(pathKey)) {
                callback = pathValueHandlerMap.get(pathKey);
                callbackPathKeys = pathKey.split("/");
                callbackPathKeys = callbackPathKeys.filter(e => !!e);
              }
            }

            if (callback) {
              callback({
                val() {
                  if (deleted) return deletedParent;
                  // @ts-ignore
                  const db = JSON.parse(
                    JSON.stringify(window.databaseInstance.mockedDb)
                  );
                  return callbackPathKeys.reduce((acc, curr) => {
                    return acc[curr];
                  }, db);
                }
              });
            }
          },
          update(updates) {
            const initialDataValue = JSON.parse(JSON.stringify(dataValue));
            const updatedDataValue = { ...initialDataValue, ...updates };

            for (let i = 0, current; i < pathKeys.length; i++) {
              if (!current) {
                // @ts-ignore
                current = window.databaseInstance.mockedDb[pathKeys[i]];
              } else {
                current = current[pathKeys[i]];
              }
              if (i === pathKeys.length - 1) {
                Object.assign(current, updatedDataValue);
              }
            }

            // check if callback exists for path, call callback
            const callback = pathValueHandlerMap.get(path);
            const childChangedCallback = pathChildChangedHandlerMap.get(path);

            if (callback) {
              callback({
                val() {
                  return updatedDataValue;
                }
              });
            }
            if (childChangedCallback) {
              Object.keys(updates).forEach(id => {
                childChangedCallback({
                  val() {
                    return updatedDataValue[id] || { ...initialDataValue[id] };
                  }
                });
              });
            }
          }
        };
      }
    };
  }

  // @ts-ignore
  return window.databaseInstance;
}

database.ServerValue = {
  // @ts-ignore
  get TIMESTAMP() {
    return new Date().getTime();
  }
};

let currentUser;
const setCurrentUser = () => {
  currentUser = {
    ...user(currentUserProviders),
    linkWithPopup(provider) {
      if (provider.providerType === ProviderTypes.Google) {
        currentUserProviders.push(providerDataGoogle);
      }
      if (provider.providerType === ProviderTypes.Facebook) {
        currentUserProviders.push(providerDataFacebook);
      }
      setCurrentUser();
      return Promise.resolve({ user: currentUser });
    },
    unlink(providerId) {
      if (providerId === ProviderTypes.Facebook) {
        currentUserProviders = currentUserProviders.filter(
          c => c.providerId !== ProviderTypes.Facebook
        );
      }
      if (providerId === ProviderTypes.Google) {
        currentUserProviders = currentUserProviders.filter(
          c => c.providerId !== ProviderTypes.Google
        );
      }
      setCurrentUser();
      return Promise.resolve();
    }
  };
};

setCurrentUser();

const firebaseAuth = () => {
  return {
    currentUser,
    GoogleAuthProvider() {
      return ProviderTypes.Google;
    },
    FacebookAuthProvider() {
      return ProviderTypes.Facebook;
    },
    signOut() {
      return Promise.resolve(firebase.onAuthStateChangedCallback());
    },
    signInWithRedirect() {
      // @ts-ignore
      firebase.onAuthStateChangedCallback(user(currentUserProviders));
      return Promise.resolve();
    },
    getRedirectResult() {
      // @ts-ignore
      firebase.onAuthStateChangedCallback(user(currentUserProviders));
      return Promise.resolve();
    },
    onAuthStateChanged(callback) {
      firebase.onAuthStateChangedCallback = callback;
      callback(user(currentUserProviders));
    }
  };
};

firebaseAuth.GoogleAuthProvider = function GoogleAuthProvider() {
  // @ts-ignore
  this.providerType = ProviderTypes.Google;
};
firebaseAuth.FacebookAuthProvider = function FacebookAuthProvider() {
  // @ts-ignore
  this.providerType = ProviderTypes.Facebook;
};

const firebase = {
  apps: [0],
  // @ts-ignore
  initializeApp(app) {},
  database,
  onAuthStateChangedCallback: () => {},
  auth: firebaseAuth
};

export { firebase };
