import { css } from "emotion";

function containerStyles({ categoryColor, hasDuration }) {
  return css`
    background: ${categoryColor};
    display: flex;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
    height: 100vh;

    svg {
      fill: rgba(255, 255, 255, 0.5);
      width: 32px;
      height: 32px;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      padding: 12px 16px;
      height: calc(100vh - 68px - 52px);
    }

    input[name="title"] {
      line-height: 30px;
      padding: 0 8px;
    }

    textarea[name="description"] {
      flex-grow: 1;
      height: 100%;
      padding: 8px;
    }

    input[name="title"],
    textarea[name="description"] {
      margin-bottom: 16px;
      box-sizing: border-box;
      background: transparent;
      border: none;
      display: block;
      outline: none;
      font-size: 18px;
      color: #fff;
      border: 1px solid #212121;
      width: 100%;
      border-radius: 4px;

      &[type="text"] {
        width: 400px;
        flex-grow: 1;
      }
    }
    input::placeholder,
    textarea::placeholder {
      color: rgba(255, 255, 255, 0.6);
      font-style: italic;
    }

    .duration {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      svg {
        fill: ${hasDuration ? "white" : "rgba(255, 255, 255, 0.6)"};
        margin-right: 4px;
      }

      input {
        background: transparent;
        border: none;
        display: block;
        outline: none;
        font-size: 16px;
        color: #fff;
        display: inline;
        width: 34px;
        text-align: right;
      }

      select,
      span {
        background: transparent;
        color: white;
        font-size: 16px;
      }

      span {
        margin-right: 4px;
      }
    }

    .button {
      padding: 8px;
      width: 48px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.delete {
        margin-right: 16px;
      }

      &.submit {
        background: white;
        width: 65px;
      }
    }
  `;
}

export { containerStyles };
