import React from "react";
import { DropMenu } from "components/DropMenu";
import { Snackbar } from "components/Snackbar";

function Shell({ userLoggedIn, dispatch, history, userPhotoURL, children }) {
  return (
    <div>
      {children}
      <DropMenu />
      <Snackbar
        id={"app-refresh"}
        messageText={"Update available"}
        actionLabel={"Refresh"}
      />
    </div>
  );
}

export default Shell;
