import { css } from "emotion";

const containerStyles = ({ backgroundColor }) => css`
  background: ${backgroundColor};
  height: 32px;
  display: flex;
  padding: 16px;
  -webkit-box-shadow: inset 0px 15px 20px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 15px 20px -16px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 15px 20px -16px rgba(0, 0, 0, 0.75);

  svg {
    fill: rgba(255, 255, 255, 0.5);
    width: 32px;
    height: 32px;
  }

  input {
    background: transparent;
    border: none;
    display: block;
    outline: none;
    font-size: 24px;
    color: #fff;

    &[type="text"] {
      width: 400px;
      flex-grow: 1;
    }
  }
  input::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-style: italic;
  }
`;

export { containerStyles };
