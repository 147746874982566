import React from "react";
import { css } from "emotion";
import { actionButtonContainer } from "./ActionButton.styles";

function ActionButton(props) {
  return (
    <div
      {...props}
      className={css`${actionButtonContainer}${props.className}`}
    />
  );
}

export { ActionButton };
