import React, { useEffect } from "react";
import { CategoryNew } from "categories/containers/CategoryNew";
import { CategoryEdit } from "categories/containers/CategoryEdit";
import { Category } from "categories/containers/Category";
import { Item } from "items/containers/Item";
import { ItemEdit } from "items/containers/ItemEdit";
import { Settings } from "user/containers/Settings";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { Login } from "user/containers/Login";
import { Categories } from "categories/containers/Categories";
import { PrivateRoute } from "components/PrivateRoute";

function Routes({ location, history }) {
  useEffect(() => {
    history.listen(() => {
      window.scrollTo(0, 0);
    });
  }, [history]);

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route
        exact
        path="/"
        render={() => {
          return <Redirect to="/categories" />;
        }}
      />
      <PrivateRoute
        exact
        history={history}
        path="/categories"
        component={Categories}
      />
      <PrivateRoute
        exact
        history={history}
        path="/categories/new"
        component={CategoryNew}
      />
      <PrivateRoute
        exact
        history={history}
        path="/categories/:categoryId/edit"
        component={CategoryEdit}
      />
      <Route
        history={history}
        path="/categories/:categoryId"
        component={Category}
      />
      <PrivateRoute
        history={history}
        path="/items/:itemId/edit"
        component={ItemEdit}
      />
      <PrivateRoute history={history} path="/items/:itemId" component={Item} />
      <PrivateRoute history={history} path="/settings" component={Settings} />
    </Switch>
  );
}

export default withRouter(Routes);
