import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getCategory, getItems } from "reducers";
import { ItemMiniForm } from "items/components/ItemMiniForm";
import { createItem, updateItem } from "items/actions";
import { deleteCategory } from "categories/actions";
import { Category as CategoryComponent } from "categories/components/Category";
import { MdReorder } from "react-icons/md";
import { containerStyle } from "./Category.styles";
import CategoryItems from "./CategoryItems";
import { toolbarStyle } from "shared/styles/toolbar";

const ITEM_STATUS = {
  completed: "completed",
  active: "active"
};

function Category(props) {
  const { category, items, location, match, history } = props;
  const { createItem, deleteCategory } = props;
  const itemsStatus = location.search.includes(ITEM_STATUS.completed)
    ? ITEM_STATUS.completed
    : ITEM_STATUS.active;

  const categoryId = match.params.categoryId;
  const [displayCompletedItems, setDisplayCompletedItems] = useState(
    itemsStatus === ITEM_STATUS.completed
  );
  const [reorder, setReorder] = useState(false);

  function handleMenuItemDeleteCategory() {
    deleteCategory(categoryId);
    history.push(`/categories/`);
  }

  function handleMenuItemEditCategory() {
    history.push(`/categories/${categoryId}/edit`);
  }

  function handleMenuItemItemStatusDisplay() {
    history.push(
      `/categories/${categoryId}${displayCompletedItems ? "" : "?completed"}`
    );
  }

  const menuItems = [
    {
      label: "Delete Category",
      onClick: handleMenuItemDeleteCategory,
      shouldConfirm: true
    },
    {
      label: "Edit Category",
      onClick: handleMenuItemEditCategory
    },
    {
      label: displayCompletedItems
        ? "Display active items"
        : "Display completed items",
      onClick: handleMenuItemItemStatusDisplay
    }
  ];

  const handleItemSubmit = useCallback(
    function handleItemSubmit(itemTitle) {
      createItem(categoryId, itemTitle);
    },
    [createItem, categoryId]
  );

  const handleClickOnCategory = useCallback(
    function handleClickOnCategory() {
      history.push(`/categories`);
    },
    [history]
  );

  function toggleReorderState() {
    setReorder(!reorder);
  }

  useEffect(
    function syncItemStatusDisplayWithLocation() {
      if (
        (itemsStatus === ITEM_STATUS.completed &&
          displayCompletedItems === false) ||
        (itemsStatus === ITEM_STATUS.active && displayCompletedItems === true)
      ) {
        setDisplayCompletedItems(!displayCompletedItems);
      }
    },
    [displayCompletedItems, itemsStatus]
  );

  if (!category) {
    return null;
  }

  return (
    <div
      className={containerStyle({
        backgroundColor: category.color,
        reorder
      })}
    >
      <CategoryComponent
        onClick={handleClickOnCategory}
        category={category}
        menuItems={menuItems}
        titleWrap
      />
      <div className={toolbarStyle({ reorder })}>
        <div data-testid="item-count">{`${items.length} ${itemsStatus} item${
          items.length === 1 ? "" : "s"
        }`}</div>
        <div className={"tools"}>
          <MdReorder onClick={toggleReorderState} />
        </div>
      </div>
      <ItemMiniForm
        onSubmit={handleItemSubmit}
        backgroundColor={category.color}
      />
      <CategoryItems
        items={items}
        category={category}
        categoryId={categoryId}
        isDragDisabled={!reorder}
        itemsStatus={itemsStatus}
      />
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    category: getCategory(state, ownProps.match.params.categoryId),
    items: getItems(state, {
      categoryId: ownProps.match.params.categoryId,
      status: ownProps.location.search.includes(ITEM_STATUS.completed)
        ? ITEM_STATUS.completed
        : ITEM_STATUS.active
    })
  }),
  {
    createItem,
    updateItem,
    deleteCategory
  }
)(Category);
