import { getUserInfo, getCategory, getCategoryIds } from "reducers";
import * as db from "./db";
import * as dbUtils from "utils/db";
import { reorderArray } from "utils/arrays";
import CategoryFactory from "./models/CategoryFactory";

/**
 * Database Events
 */
export const mapDispatchToDatabaseEvents = (store, userId) => {
  dbUtils.addDatabaseEventListener(db.refs.byId(userId))(
    onCategoriesByIdChanged(store)
  );
  dbUtils.addDatabaseEventListener(db.refs.allIds(userId))(
    onCategoriesAllIdsChanged(store)
  );
};
export const onCategoriesByIdChanged = store => snapshot => {
  store.dispatch({
    type: "CATEGORIES_DATA_UPDATE",
    categories: snapshot.val()
  });
};
export const onCategoriesAllIdsChanged = store => snapshot => {
  store.dispatch({
    type: "CATEGORIES_IDS_UPDATE",
    categoryIds: snapshot.val()
  });
};

/**
 * User actions
 */
export const createCategory = category => async (dispatch, getState) => {
  dispatch({ type: "CATEGORY_CREATE" });

  const state = getState();
  const userId = getUserInfo(state).uid;

  try {
    await db.Category.create(userId, CategoryFactory(category));
    dispatch({ type: "CATEGORY_CREATE_SUCCESS" });
  } catch (error) {
    console.error(error);
    dispatch({ type: "CATEGORY_CREATE_FAILURE" });
  }
};
export const updateCategory = (id, updates) => async (dispatch, getState) => {
  dispatch({ type: "CATEGORY_UPDATE" });

  const state = getState();
  const userId = getUserInfo(state).uid;
  const category = getCategory(state, id);

  try {
    await db.Category.update(userId, { ...category, ...updates });
    dispatch({ type: "CATEGORY_UPDATE_SUCCESS" });
  } catch (error) {
    console.error(error);
    dispatch({ type: "CATEGORY_UPDATE_FAILURE" });
  }
};
export const deleteCategory = categoryId => async (dispatch, getState) => {
  dispatch({ type: "CATEGORY_DELETE" });

  const state = getState();
  const userId = getUserInfo(state).uid;

  try {
    await db.Category.delete(userId, categoryId);
    dispatch({ type: "CATEGORY_DELETE_SUCCESS" });
  } catch (error) {
    console.error(error);
    dispatch({ type: "CATEGORY_DELETE_FAILURE" });
  }
};

export const orderCategories = ({ oldIndex, newIndex }) => async (
  dispatch,
  getState
) => {
  if (oldIndex === newIndex) return;
  dispatch({ type: "CATEGORIES_ORDER" });

  const state = getState();
  const userId = getUserInfo(state).uid;
  const oldAllIds = getCategoryIds(state);
  const newAllIds = reorderArray({ oldIndex, newIndex }, oldAllIds);
  try {
    await db.Categories.updateAllIds(userId, newAllIds);
    dispatch({ type: "CATEGORIES_ORDER_SUCCESS" });
  } catch (error) {
    console.error(error);
    dispatch({ type: "CATEGORIES_ORDER_FAILURE" });
  }
};
