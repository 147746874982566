let firebase;
if (process.env.REACT_APP_MOCK_FIREBASE === "true") {
  firebase = require("../mocks/firebase").firebase;
  console.log("*** FIREBASE MOCKED ***");
} else {
  firebase = require("firebase/app");
  require("firebase/auth");
  require("firebase/database");
}

export default firebase;
