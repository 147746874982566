import { css } from "emotion";

const containerStyle = css`
  min-height: 56px;
  position: fixed;
  background: #212121;
  bottom: 16px;
  left: 8px;
  right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;

  .message {
    color: white;
  }

  .action {
    color: rgb(233, 86, 128);
  }
`;

export { containerStyle };
