import React from "react";
import { render } from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import Shell from "Shell";
import { Provider } from "react-redux";
import firebase from "utils/firebase";
import configureStore from "configureStore";
import { IconContext } from "react-icons";
import * as serviceWorker from "./serviceWorker";
import { Routes } from "./components/Routes";
import { addAuthStateChangedEventListener } from "./user/auth";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    databaseURL: process.env.REACT_APP_databaseURL,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId
  });
}

const rootElement = document.getElementById("root");
const store = configureStore();
const history = createBrowserHistory();
addAuthStateChangedEventListener({ store, history });

render(
  <Provider store={store}>
    <IconContext.Provider
      value={{ color: "blue", className: "global-class-name" }}
    >
      <Shell history={history}>
        <Router history={history}>
          <Routes />
        </Router>
      </Shell>
    </IconContext.Provider>
  </Provider>,
  rootElement
);

var refreshing;
navigator.serviceWorker.addEventListener("controllerchange", function() {
  if (refreshing) return;
  refreshing = true;
  window.location.reload();
});

function promptUserToRefresh(reg) {
  const customEvent = new CustomEvent("display-snackbar", {
    detail: {
      handleAction: () => reg.waiting.postMessage({ type: "SKIP_WAITING" })
    }
  });
  document.dispatchEvent(customEvent);
}

serviceWorker.register({ onUpdate: promptUserToRefresh });
