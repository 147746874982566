import React from "react";
import * as reactStringReplace from "react-string-replace";
export const kebabToCapitalizedCamelCase = str => {
  const camelCaseString = str
    .replace(/^icons:/, "")
    .replace(/-([a-z])/g, function(g) {
      return g[1].toUpperCase();
    });
  return camelCaseString.charAt(0).toUpperCase() + camelCaseString.slice(1);
};

export const htmlFormat = text => {
  var exp = /(https?:\/\/\S+)/g;
  return text.match(exp) !== null
    ? reactStringReplace(text, exp, (match, i) => (
        <a key={i} target="_blank" rel="noopener noreferrer" href={match}>
          {match}
        </a>
      ))
    : text;
};
export const isStringNonEmpty = str => typeof str === "string" && str !== "";
