import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCategories,
  userStatus,
  userLoggedIn,
  getUserPhotoURL
} from "reducers";
import { ActionButton } from "components/ActionButton";
import * as MdIcons from "react-icons/md";
import { orderCategories } from "categories/actions";
import CategoryList from "./CategoryList";
import { Header } from "components/Header";
import { Sidebar, SidebarMenuItem } from "components/Sidebar";
import { signout } from "user/actions";
import { userPhoto, reorderIcon } from "./Categories.styles";

function Categories({
  categories,
  orderCategories: dispatchOrderCategories,
  userLoggedIn,
  userPhotoURL,
  history,
  signout: dispatchSignout
}) {
  const [reorder, setReorder] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = useCallback(
    function toggleSidebar() {
      setSidebarOpen(!sidebarOpen);
    },
    [setSidebarOpen, sidebarOpen]
  );
  const toggleReorder = useCallback(
    function toggleReorder() {
      setReorder(!reorder);
    },
    [reorder, setReorder]
  );

  return (
    <div>
      <Header
        onMenuIconClick={toggleSidebar}
        userLoggedIn={userLoggedIn}
        userPhotoURL={userPhotoURL}
      >
        <div onClick={toggleReorder}>
          <MdIcons.MdReorder className={reorderIcon({ reorder })} />
        </div>
      </Header>
      <Sidebar open={sidebarOpen} handleBlur={toggleSidebar}>
        {userLoggedIn && [
          <SidebarMenuItem key={"photo"}>
            {userLoggedIn && userPhotoURL && (
              <div className={userPhoto}>
                <img alt="Signed In" src={userPhotoURL} />
              </div>
            )}
          </SidebarMenuItem>,
          <SidebarMenuItem
            key={"settings"}
            onClick={() => {
              history.push("/settings");
              toggleSidebar();
            }}
          >
            {"Settings"}
          </SidebarMenuItem>,
          <SidebarMenuItem
            key={"logout"}
            onClick={() => {
              dispatchSignout(history);
              toggleSidebar();
            }}
          >
            {"Logout"}
          </SidebarMenuItem>
        ]}
      </Sidebar>
      <CategoryList
        isDragDisabled={!reorder}
        categories={categories}
        orderCategories={dispatchOrderCategories}
      />
      <ActionButton>
        <Link to={`/categories/new`}>
          <MdIcons.MdAdd />
        </Link>
      </ActionButton>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    categories: getCategories(state),
    userStatus: userStatus(state),
    userLoggedIn: userLoggedIn(state),
    userPhotoURL: getUserPhotoURL(state)
  };
}

export default connect(
  mapStateToProps,
  { orderCategories, signout }
)(Categories);
