import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { USER_STATUS } from "user/reducers";

const delayRedirect = (history, to) => {
  const { push } = history;
  setTimeout(() => push(to), 1000);
  return true;
};

const PrivateRoute = ({
  user,
  component: Component,
  history,
  path,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      path={path}
      render={props => {
        switch (user.status) {
          case USER_STATUS.SIGNED_IN:
            return <Component {...props} />;
          case USER_STATUS.SIGNED_OUT:
            return <Redirect to="/login" />;
          default:
            return delayRedirect(history, "/categories") && <div />;
        }
      }}
    />
  );
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(PrivateRoute);
