import React, { useCallback } from "react";
import { containerStyles } from "./Item.styles";
import { MdDone, MdReplay, MdMoreVert, MdReorder } from "react-icons/md";

function Item({
  item,
  onItemStatusToggle,
  backgroundColor,
  overlayOpacity = 0,
  innerDragHandleProps,
  titleWrap = false,
  isDragDisabled,
  onClick,
  menuItems
}) {
  const displayMenu = useCallback(
    function displayMenu(e) {
      e.preventDefault();
      e.currentTarget.dispatchEvent(
        new CustomEvent("toggleMenu", {
          detail: {
            items: menuItems
          },
          bubbles: true
        })
      );
    },
    [menuItems]
  );

  return (
    <li
      className={containerStyles({
        overlayOpacity,
        backgroundColor,
        titleWrap
      })}
    >
      {!isDragDisabled && innerDragHandleProps ? (
        <div {...innerDragHandleProps}>
          <div className={"actions"}>
            <MdReorder />
          </div>
        </div>
      ) : (
        <button className="completion" onClick={onItemStatusToggle}>
          {item.completed ? (
            <MdReplay className="icon" />
          ) : (
            <MdDone className="icon" />
          )}
        </button>
      )}

      <div className="title" onClick={onClick}>
        {item.title}
      </div>

      {menuItems && (
        <button className="actions-menu" onClick={displayMenu}>
          <MdMoreVert />
        </button>
      )}
    </li>
  );
}

export { Item };
