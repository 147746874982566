import { css } from "emotion";

const containerStyles = ({ overlayOpacity, backgroundColor, titleWrap }) => css`
  display: flex;
  padding: 0 0 0 16px;
  align-items: center;
  min-height: 56px;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, ${overlayOpacity}),
      rgba(0, 0, 0, ${overlayOpacity})
    ),
    linear-gradient(to right, ${backgroundColor}, ${backgroundColor});

  a {
    line-height: 32px;
    font-size: 18px;
    color: white;
    text-decoration: none;
  }

  button.completion {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  svg {
    width: 32px;
    height: 32px;
  }

  .title {
    line-height: 32px;
    font-size: 18px;
    color: white;
    padding: 12px 16px 12px 0;
    flex-grow: 1;
    white-space: ${titleWrap ? "normal" : "nowrap"};
    overflow: hidden;
    text-overflow: ${titleWrap ? "initial" : "ellipsis"};
    word-break: break-word;
  }

  .icon {
    fill: rgba(255, 255, 255, 0.5);
  }

  .actions {
    padding: 0px 16px 0 0;
    width: 32px;
    height: 32px;
    svg {
      fill: rgba(0, 0, 0, 0.5);
    }
  }
  .actions-menu {
    padding: 12px 16px;
    width: 64px;
    height: 56px;
    box-sizing: border-box;
    svg {
      fill: rgba(0, 0, 0, 0.5);
    }
  }
`;

export { containerStyles };
