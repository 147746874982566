export const alphabeticalSort = collection =>
  collection.sort((a, b) => a.localeCompare(b));

export const propertySort = (collection, property) => {
  if (!property) return collection;
  return collection.sort((a, b) =>
    a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
  );
};

export const alphabeticalInsertion = (id, collection = []) => {
  let i = 0;
  let idAlreadyInCollection = false;
  for (; i <= collection.length; i++) {
    // End of collection
    if (i === collection.length) {
      break;
    }
    const compareScore = id.localeCompare(collection[i]);
    // Id is already in collection
    if (compareScore === 0) {
      idAlreadyInCollection = true;
      break;
    }
    // Id will be inserted in collection at index i
    if (compareScore === -1) {
      break;
    }
  }
  return idAlreadyInCollection
    ? collection
    : collection
        .slice(0, i)
        .concat([id])
        .concat(collection.slice(i));
};

export const reorderArray = ({ oldIndex, newIndex }, originalArray) => {
  const movedItem = originalArray.find((item, index) => index === oldIndex);
  const remainingItems = originalArray.filter(
    (item, index) => index !== oldIndex
  );

  const reorderedItems = [
    ...remainingItems.slice(0, newIndex),
    movedItem,
    ...remainingItems.slice(newIndex)
  ];

  return reorderedItems;
};

export const getRandomElement = arr =>
  arr[Math.floor(Math.random() * arr.length)];
