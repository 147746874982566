import { combineReducers } from "redux";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "ITEMS_DATA_UPDATE":
      return action.byId || {};
    default:
      return state;
  }
};

const byCategoryId = (state = {}, action) => {
  switch (action.type) {
    case "ITEMS_CATEGORIES_UPDATE":
      return action.byCategoryId || {};
    default:
      return state;
  }
};

const fetched = (state = false, action) => {
  switch (action.type) {
    case "ITEMS_FETCH_SUCCESS":
      return true;
    default:
      return state;
  }
};

export const items = combineReducers({
  byId,
  byCategoryId,
  fetched
});

export default items;

export const getItems = (state, filters) => {
  const { categoryId, status = "active" } = filters;
  const { byId, byCategoryId } = state;

  if (!categoryId || !byCategoryId.hasOwnProperty(categoryId)) {
    return [];
  }

  return (byCategoryId[categoryId][status] || [])
    .map(id =>
      byId[id]
        ? {
            ...byId[id]
          }
        : undefined
    )
    .filter(Boolean);
};

export const getItemsByCategory = ({ byCategoryId }, categoryId) => ({
  active: byCategoryId[categoryId].active || [],
  completed: byCategoryId[categoryId].completed || []
});

export const getItem = (state, id) => ({
  id,
  ...state.byId[id]
});
