import { css } from "emotion";

function containerStyles({ backgroundColor }) {
  return css`
    background: ${backgroundColor};
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: 100vh;

    input {
      background: transparent;
      border: none;
      display: block;
      outline: none;
      font-size: 24px;
      color: #fff;
      flex-grow: 1;
    }
    input::placeholder {
      color: rgba(255, 255, 255, 0.6);
      font-style: italic;
    }

    .actions {
      display: flex;
      margin-bottom: 16px;

      .cancel {
        padding: 8px;
        width: 33%;
        border-radius: 5px;
        flex-grow: 1;
        background: white;
        margin-right: 16px;
      }
      .submit {
        padding: 8px;
        width: 66%;
        border-radius: 5px;
        flex-grow: 1;
        background: white;
      }
    }

    .inputs {
      display: flex;
      margin-bottom: 16px;
      align-items: center;

      svg {
        margin-right: 8px;
        fill: rgba(0, 0, 0, 0.5);
        width: 32px;
        height: 32px;
      }
    }
  `;
}

export { containerStyles };
