import { css } from "emotion";

const actionButtonContainer = css`
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: 56px;
  height: 56px;
  border-radius: 56px;
  font-size: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgb(233, 86, 128);
  a {
    display: flex;
  }
  svg {
    fill: white;
  }
`;

export { actionButtonContainer };
