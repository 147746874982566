import React from "react";
import {
  sidebarContainerStyles,
  sidebarMenuItemStyles,
  mainLogoStyles
} from "./Sidebar.styles";
import { Logo } from "components/Logo";

function Sidebar({ handleBlur, open, children }) {
  return (
    <div className={sidebarContainerStyles}>
      <div
        onClick={handleBlur}
        className={`overlay ${open ? "display" : ""}
        `}
      />
      <div
        className={`sidebar ${open ? "open" : ""}
        `}
      >
        <SidebarMenuItem>
          <div className={"app-title"}>
            <Logo className={mainLogoStyles} />
            <span>Calify</span>
          </div>
        </SidebarMenuItem>
        {children}
      </div>
    </div>
  );
}

function SidebarMenuItem({ onClick, active, children }) {
  return (
    <div
      onClick={onClick}
      className={`${sidebarMenuItemStyles} ${active ? "active" : ""}`}
    >
      {children}
    </div>
  );
}

export { Sidebar, SidebarMenuItem };
