import { css } from "emotion";

const containerStyles = ({ categoryColor }) => css`
  background: ${categoryColor};
  height: 100vh;
  display: flex;
  flex-direction: column;
  p {
    margin: 16px;
    color: white;
    white-space: pre-line;

    &.description {
      overflow: scroll;
      height: 100%;
    }
  }

  .duration {
    display: flex;
    align-items: center;

    svg {
      fill: white;
      margin-right: 4px;
    }
  }

  a {
    color: white;
  }
`;
export { containerStyles };
