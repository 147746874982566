const CategoryFactory = ({
  color = "#123456",
  icon = "icons:polymer",
  title = "untitled"
}) => {
  return {
    color,
    icon,
    title: title === "" ? "untitled" : title
  };
};

export default CategoryFactory;
