import { css } from "emotion";

const tabsContainerClassName = css`
  .tabs {
    display: flex;
    margin: 0 auto;
    width: 320px;

    > div {
      width: 50%;
      text-align: center;
      color: white;
      margin: 0 0 16px 0;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .selected {
      border-bottom: 2px solid white;
    }
  }

  .content {
    display: flex;
    justify-content: center;
  }
`;

export { tabsContainerClassName };
