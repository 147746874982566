import { css } from "emotion";

const containerStyles = css`
  overflow: auto;
  max-height: 430px;
  width: 320px;
  background: white;
  svg {
    fill: black;
    width: 32px;
    height: 32px;
  }
`;

export { containerStyles };
