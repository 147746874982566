import { getUserInfo, getItem, getItemsByCategory } from "reducers";
import * as db from "./db";
import * as dbUtils from "utils/db";
import * as arraysUtils from "utils/arrays";
import ItemFactory from "./models/ItemFactory";

/**
 * Database Events
 */
export const mapDispatchToDatabaseEvents = (store, userId) => {
  dbUtils.addDatabaseEventListener(db.refs.byId(userId))(
    onItemsByIdChanged(store)
  );
  dbUtils.addDatabaseEventListener(db.refs.byCategoryId(userId))(
    onItemsByCategoryIdChanged(store)
  );
};

export const onItemsByIdChanged = store => snapshot => {
  store.dispatch({
    type: "ITEMS_DATA_UPDATE",
    byId: snapshot.val()
  });
};
export const onItemsByCategoryIdChanged = store => snapshot => {
  store.dispatch({
    type: "ITEMS_CATEGORIES_UPDATE",
    byCategoryId: snapshot.val()
  });
};

/**
 * User actions
 */
export const createItem = (categoryId, title) => async (dispatch, getState) => {
  dispatch({ type: "ITEM_CREATE" });

  const state = getState();
  const userId = getUserInfo(state).uid;

  try {
    const item = ItemFactory({
      categoryId,
      title
    });
    await db.Items.create(userId, item);
    dispatch({ type: "ITEM_CREATE_SUCCESS" });
  } catch (error) {
    console.error(error);
    dispatch({ type: "ITEM_CREATE_FAILURE" });
  }
};

export const updateItem = (itemId, updatedFields) => async (
  dispatch,
  getState
) => {
  dispatch({ type: "ITEM_UPDATE" });

  const state = getState();
  const userId = getUserInfo(state).uid;
  const previousItem = getItem(state, itemId);

  try {
    const item = {
      ...previousItem,
      ...updatedFields
    };
    await db.Items.update(userId, item);
    dispatch({ type: "ITEM_UPDATE_SUCCESS" });
  } catch (error) {
    console.error(error);
    dispatch({ type: "ITEM_UPDATE_FAILURE" });
  }
};

export const deleteItem = itemId => async (dispatch, getState) => {
  dispatch({ type: "ITEM_DELETE" });

  const state = getState();
  const userId = getUserInfo(state).uid;

  try {
    await db.Items.delete(userId, itemId);
    dispatch({ type: "ITEM_DELETE_SUCCESS" });
  } catch (error) {
    console.error(error);
    dispatch({ type: "ITEM_DELETE_FAILURE" });
  }
};

export const orderItems = ({ categoryId, filter }) => ({
  oldIndex,
  newIndex
}) => async (dispatch, getState) => {
  if (oldIndex === newIndex) return;
  dispatch({ type: "ITEMS_ORDER" });

  const state = getState();
  const userId = getUserInfo(state).uid;
  const { [filter]: oldItemIds } = getItemsByCategory(state, categoryId);
  const newItemIds = arraysUtils.reorderArray(
    { oldIndex, newIndex },
    oldItemIds
  );
  try {
    await db.Items.updateByCategoryIds(userId, categoryId, filter, newItemIds);
    dispatch({ type: "ITEMS_ORDER_SUCCESS" });
  } catch (error) {
    console.error(error);
    dispatch({ type: "ITEMS_ORDER_FAILURE" });
  }
};
