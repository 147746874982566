import { css } from "emotion";

const container = css`
  color: white;
  margin: 0 16px;
  h1,
  h3 {
    font-weight: normal;
  }

  .account-method {
    margin-bottom: 16px;
  }

  .button {
    background: white;
    padding: 8px;
    width: 100%;
    margin: 8px auto 8px 0;
    border-radius: 5px;
    display: flex;
    height: 42px;
    align-items: center;
    max-width: 250px;
    justify-content: center;

    img {
      height: 24px;
      margin: 0 8px 2px 4px;
    }
    &.facebook {
      background: #4267b2;
      color: white;
    }
    &[disabled] {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .error-message {
    color: red;
    margin-bottom: 16px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 8px;
  }

  input[type="email"] {
    background: transparent;
    display: block;
    box-sizing: border-box;
    margin: 8px 0;
    padding: 8px;
    width: 80%;
    max-width: 250px;
    height: 32px;
    border: none;
    border-bottom: 1px solid #aaa;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
  }

  input[type="email"]:focus {
    outline: none;
    border-bottom: 2px solid #fff;
  }
`;

export { container };
