import React, { useCallback } from "react";
import { connect } from "react-redux";
import { getItem, getCategory } from "reducers";
import { updateItem } from "items/actions";
import { ItemForm } from "items/components/ItemForm";
import { Category as CategoryComponent } from "categories/components/Category";

function EditItem({ item, category, updateItem, history }) {
  const handleItemEditSubmit = useCallback(
    function handleItemEditSubmit(editedItem) {
      updateItem(item.id, editedItem);
      history.push(`/items/${item.id}`);
    },
    [history, updateItem, item.id]
  );

  return (
    <div>
      <CategoryComponent
        to={`/categories/${item.categoryId}`}
        category={category}
      />
      <ItemForm
        className="form"
        onSubmit={handleItemEditSubmit}
        item={item}
        category={category}
      />
    </div>
  );
}

export default connect(
  (state, ownProps) => {
    const item = getItem(state, ownProps.match.params.itemId);
    return {
      category: getCategory(state, item.categoryId),
      item
    };
  },
  { updateItem }
)(EditItem);
