import React, { useState, useCallback, useEffect, useRef } from "react";
import { SwatchesPicker } from "react-color";
import { IconPicker } from "components/IconPicker";
import * as MdIcons from "react-icons/md";
import { Tabs } from "components/Tabs";
import { containerStyles } from "./CategoryForm.styles";
import { getRandomElement } from "../../../utils/arrays";
import { withRouter } from "react-router-dom";

const MdIconsKeys = Object.keys(MdIcons);
const CategoryColors = [
  ["#f44336", "#e53935", "#d32f2f", "#c62828", "#b71c1c"],
  ["#e91e63", "#d81b60", "#c2185b", "#ad1457", "#880e4f"],
  ["#9c27b0", "#8e24aa", "#7b1fa2", "#6a1b9a", "#4a148c"],
  ["#673ab7", "#5e35b1", "#512da8", "#4527a0", "#311b92"],
  ["#3f51b5", "#3949ab", "#303f9f", "#283593", "#1a237e"],
  ["#2196f3", "#1e88e5", "#1976d2", "#1565c0", "#0d47a1"],
  ["#03a9f4", "#039be5", "#0288d1", "#0277bd", "#01579b"],
  ["#00bcd4", "#00acc1", "#0097a7", "#00838f", "#006064"],
  ["#009688", "#00897b", "#00796b", "#00695c", "#004d40"],
  ["#4caf50", "#43a047", "#388e3c", "#2e7d32", "#1b5e20"],
  ["#8bc34a", "#7cb342", "#689f38", "#558b2f", "#33691e"],
  ["#cddc39", "#c0ca33", "#afb42b", "#9e9d24", "#827717"],
  ["#ffeb3b", "#fdd835", "#fbc02d", "#f9a825", "#f57f17"],
  ["#ffc107", "#ffb300", "#ffa000", "#ff8f00", "#ff6f00"],
  ["#ff9800", "#fb8c00", "#f57c00", "#ef6c00", "#e65100"],
  ["#ff5722", "#f4511e", "#e64a19", "#d84315", "#bf360c"],
  ["#795548", "#6d4c41", "#5d4037", "#4e342e", "#3e2723"],
  ["#9e9e9e", "#757575", "#616161", "#424242", "#212121"]
];

const CategoryColorsFlat = CategoryColors.reduce((acc, row) => {
  return acc.concat(row);
}, []);

function CategoryForm({ category, onSubmit, history }) {
  const [color, setColor] = useState(
    category ? category.color : getRandomElement(CategoryColorsFlat)
  );
  const [icon, setIcon] = useState(
    category ? category.icon : getRandomElement(MdIconsKeys)
  );
  const [title, setTitle] = useState(category ? category.title : "");
  const [searchIcon, setSearchIcon] = useState("");

  const handleSubmit = useCallback(
    function handleSubmit(e) {
      e.preventDefault();
      onSubmit({
        color,
        icon,
        title
      });
    },
    [color, icon, title, onSubmit]
  );

  const handleCancel = useCallback(
    function handleCancel(e) {
      history.push(category ? `/categories/${category.id}` : `/categories`);
    },
    [history, category]
  );

  function handleTitleChange({ target: { value } }) {
    setTitle(value);
  }

  function handleSearchIconChange({ target: { value } }) {
    setSearchIcon(value);
  }

  function handleIconChange(icon) {
    setIcon(icon);
  }

  function handleColorChange({ hex: color }) {
    setColor(color);
  }

  const titleInputRef = useRef();
  useEffect(() => {
    const titleInputElement = titleInputRef.current;
    if (titleInputElement) {
      titleInputElement.focus();
    }
  }, []);

  return (
    <form
      className={containerStyles({ backgroundColor: color })}
      onSubmit={handleSubmit}
    >
      <div className={"actions"}>
        <button className="cancel" type="button" onClick={handleCancel}>
          Cancel
        </button>
        <button className="submit" type="submit">
          Save
        </button>
      </div>
      <div className={"inputs"}>
        {icon && React.createElement(MdIcons[icon])}
        <input
          ref={titleInputRef}
          required={true}
          type="text"
          value={title}
          onChange={handleTitleChange}
          name="title"
          placeholder="Type a Category Name"
        />
      </div>
      <Tabs labels={["Colors", "Icons"]}>
        <SwatchesPicker
          onChange={handleColorChange}
          height={430}
          colors={CategoryColors}
        />
        <div>
          <input
            onChange={handleSearchIconChange}
            type="text"
            value={searchIcon}
            placeholder="Search Icons..."
          />
          <IconPicker onChange={handleIconChange} search={searchIcon} />
        </div>
      </Tabs>
    </form>
  );
}

export default withRouter(CategoryForm);
