import { css } from "emotion";

const toolbarStyle = ({ reorder, edit }) => css`
  display: flex;
  background: #212121;
  align-items: center;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.5);
  padding: 8px 16px;
  font-style: italic;

  .actions {
    display: flex;
    width: 100%;

    .cancel {
      padding: 8px;
      width: 33%;
      height: 36px;
      border-radius: 5px;
      flex-grow: 1;
      background: white;
      margin-right: 16px;
    }
    .submit {
      height: 36px;
      padding: 8px;
      width: 66%;
      border-radius: 5px;
      flex-grow: 1;
      background: white;
    }
  }

  .tools {
    svg {
      fill: ${reorder || edit ? "white" : "rgba(255, 255, 255, 0.5)"};
      width: 32px;
      height: 32px;
    }
  }
`;

export { toolbarStyle };
