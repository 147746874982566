import React, { useCallback } from "react";
import * as MdIcons from "react-icons/md";
import { containerStyles } from "./IconPicker.styles";

function IconPicker({ onChange, search = "" }) {
  const toLowerCaseSearch = search.toLowerCase();

  const renderMatchedIcon = useCallback(
    function renderMatchedIcon(iconId) {
      if (search !== "" && !iconId.toLowerCase().includes(toLowerCaseSearch)) {
        return null;
      }
      const IconComponent = MdIcons[iconId];
      return (
        <span key={iconId} className={iconId} onClick={() => onChange(iconId)}>
          <IconComponent size={24} />
        </span>
      );
    },
    [search, onChange, toLowerCaseSearch]
  );

  return (
    <div className={containerStyles}>
      {Object.keys(MdIcons).map(renderMatchedIcon)}
    </div>
  );
}

export { IconPicker };
