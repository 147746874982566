import { css } from "emotion";

function containerStyles(color, titleWrap) {
  return css`
    background: ${color};
    color: white;
    min-height: 68px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .category {
      flex-grow: 1;
      font-size: 24px;
      color: white;
      user-drag: none;
      align-items: center;
      text-decoration: none;
      box-sizing: border-box;
      overflow: hidden;
      user-select: none;
      display: flex;
      padding: 16px;

      .icon {
        height: 32px;
        width: 32px;
        margin-right: 16px;
      }
      .title {
        white-space: ${titleWrap ? "normal" : "nowrap"};
        word-break: break-word;
        overflow: hidden;
        text-overflow: ${titleWrap ? "initial" : "ellipsis"};
        user-select: none;
      }
    }
    svg {
      fill: rgba(0, 0, 0, 0.5);
      width: 32px;
      height: 32px;
    }
  `;
}

export { containerStyles };
