import React, { useCallback } from "react";
import * as MdIcons from "react-icons/md";
import { containerStyles } from "./Category.styles";

function Category({
  category,
  innerDragHandleProps,
  titleWrap = false,
  onClick,
  menuItems = undefined,
  isDragDisabled
}) {
  const displayMenu = useCallback(
    function displayMenu() {
      document.dispatchEvent(
        new CustomEvent("toggleMenu", {
          detail: {
            items: menuItems
          }
        })
      );
    },
    [menuItems]
  );

  if (!category) return null;

  return (
    <div className={containerStyles(category.color, titleWrap)}>
      <div className="category" onClick={onClick}>
        {!isDragDisabled && innerDragHandleProps ? (
          <div {...innerDragHandleProps}>
            <div className={"icon"}>
              <MdIcons.MdReorder />
            </div>
          </div>
        ) : (
          <div
            className="icon"
            data-testid={category.icon}
            {...innerDragHandleProps}
          >
            {category.icon && React.createElement(MdIcons[category.icon])}
          </div>
        )}
        <div className="title">{category.title}</div>
      </div>
      {menuItems && (
        <button
          className="category-actions"
          data-testid="category-actions"
          onClick={displayMenu}
        >
          <MdIcons.MdMoreVert />
        </button>
      )}
    </div>
  );
}

export { Category };
