import firebase from "utils/firebase";
import * as fromItemsDb from "items/db";
import { array, data, dictionary } from "utils/db";

/**
 * References
 */
export const refs = {
  db() {
    return firebase.database();
  },
  byId(userId, id = "") {
    return this.db().ref(`/data/categories/${userId}/byId/${id}`);
  },
  allIds(userId) {
    return this.db().ref(`/data/categories/${userId}/allIds`);
  }
};

export const Category = {
  async create(userId, newCategory) {
    const id = await dictionary.create(refs.byId(userId), newCategory);
    await array.unshift(refs.allIds(userId), id);
  },
  async delete(userId, id) {
    await data.delete(refs.byId(userId, id));
    await array.deleteValue(refs.allIds(userId), id);
    await fromItemsDb.Items.deleteByCategory(userId, id);
  },
  async update(userId, updatedCategory) {
    await dictionary.update(refs.byId(userId), updatedCategory);
  }
};
export const Categories = {
  async updateAllIds(userId, allIds) {
    await array.update(refs.allIds(userId), allIds);
  }
};
