import React, { useState, useCallback } from "react";
import { tabsContainerClassName } from "./Tabs.styles";

function Tab({ selected, onClick: handleClick, children }) {
  return (
    <div className={`${selected ? "selected" : ""}`} onClick={handleClick}>
      {children}
    </div>
  );
}

function Tabs({ children, labels }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const renderTabs = useCallback(
    function renderTabs(label, index) {
      return (
        <Tab
          key={index}
          selected={selectedIndex === index}
          onClick={() => setSelectedIndex(index)}
        >
          {label}
        </Tab>
      );
    },
    [selectedIndex]
  );

  return (
    <div className={tabsContainerClassName}>
      <div className={"tabs"}>{labels.map(renderTabs)}</div>
      <div className={"content"}>{children[selectedIndex]}</div>
    </div>
  );
}

export { Tabs };
