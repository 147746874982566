import React, { useCallback } from "react";
import { connect } from "react-redux";
import { createCategory } from "categories/actions";
import { CategoryForm } from "categories/components/CategoryForm";

function CreateCategory({ createCategory: dispatchCreateCategory, history }) {
  const handleSubmit = useCallback(
    function handleSubmit(category) {
      dispatchCreateCategory(category);
      history.push("/categories");
    },
    [dispatchCreateCategory, history]
  );

  return (
    <div>
      <CategoryForm onSubmit={handleSubmit} />
    </div>
  );
}

export default connect(
  undefined,
  { createCategory }
)(CreateCategory);
