import { combineReducers } from "redux";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "CATEGORIES_DATA_UPDATE":
      return action.categories || {};
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case "CATEGORIES_IDS_UPDATE":
      return action.categoryIds || [];
    default:
      return state;
  }
};

export const categories = combineReducers({
  byId,
  allIds
});

export default categories;

export const getCategory = (state, id) => state.byId[id];
