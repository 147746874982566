import React, { useCallback } from "react";
import { connect } from "react-redux";
import { getItem, getCategory } from "reducers";
import { Item as ItemComponent } from "items/components/Item";
import { Category as CategoryComponent } from "categories/components/Category";
import { updateItem, deleteItem } from "items/actions";
import { MdTimer, MdModeEdit } from "react-icons/md";
import { htmlFormat } from "utils/strings";
import { containerStyles } from "./Item.styles";
import { toolbarStyle } from "shared/styles/toolbar";
import { distanceInWords } from "date-fns";

function Item({
  history,
  updateItem: dispatchUpdateItem,
  deleteItem: dispatchDeleteItem,
  item,
  category
}) {
  const handleCategoryClick = useCallback(
    function handleCategoryClick() {
      history.push(`/categories/${category.id}`);
    },
    [history, category.id]
  );

  const toggleItemStatus = useCallback(
    function toggleItemStatus() {
      dispatchUpdateItem(item.id, {
        completed: !item.completed
      });
    },
    [item, dispatchUpdateItem]
  );

  const handleDeleteItem = useCallback(
    function handleDeleteItem() {
      dispatchDeleteItem(item.id);
      history.push(`/categories/${category.id}`);
    },
    [category, history, item, dispatchDeleteItem]
  );

  const handleEditItem = useCallback(
    function handleEditItem() {
      history.push(`/items/${item.id}/edit`);
    },
    [item, history]
  );

  const menuItems = [
    {
      label: "Delete Item",
      onClick: handleDeleteItem,
      shouldConfirm: true
    }
  ];

  if (!item) {
    return null;
  }

  return (
    <div className={containerStyles({ categoryColor: category.color })}>
      <CategoryComponent category={category} onClick={handleCategoryClick} />
      <div className={toolbarStyle({ edit: false })}>
        <div data-testid="last-edited-timestamp">{`Last edited ${distanceInWords(
          new Date(item.updatedOn),
          new Date(),
          { includeSeconds: true }
        )} ago`}</div>
        <div className={"tools"}>
          <MdModeEdit onClick={handleEditItem} />
        </div>
      </div>
      <ItemComponent
        item={item}
        backgroundColor={category.color}
        onItemStatusToggle={toggleItemStatus}
        titleWrap
        menuItems={menuItems}
      />
      {item.duration && (
        <p className="duration">
          <MdTimer size={32} />
          {`${item.duration.durationValue} ${item.duration.durationUnit}`}
        </p>
      )}
      {item.description && (
        <p className="description">
          {htmlFormat(
            `${item.link ? `${item.link}\n` : ""}${item.description}`
          )}
        </p>
      )}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const item = getItem(state, ownProps.match.params.itemId);
  return {
    category: getCategory(state, item.categoryId),
    item
  };
}

export default connect(
  mapStateToProps,
  { updateItem, deleteItem }
)(Item);
