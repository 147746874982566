import firebase from "utils/firebase";
export const userInfoGetSuccess = user => {
  const { displayName, email, photoURL, uid, providerData } = user;

  return {
    type: "USER_INFO_GET_SUCCESS",
    payload: {
      displayName,
      email,
      photoURL,
      uid,
      providerData
    }
  };
};

export const userStatusSet = status => ({ type: "USER_STATUS_SET", status });

export const signout = history => async dispatch => {
  dispatch({ type: "USER_SIGNOUT" });

  try {
    await firebase.auth().signOut();
    dispatch({ type: "USER_SIGNOUT_SUCCESS" });
    dispatch({ type: "APP_RESET" });
    history.push("/login");
  } catch (error) {
    console.error(error);
    dispatch({ type: "USER_SIGNOUT_FAILURE", error });
  }
};
