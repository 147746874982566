import { css } from "emotion";

const marginBottom = n => {
  return css`
    margin-bottom: ${n * 8}px;
  `;
};

const marginTop = n => {
  return css`
    margin-top: ${n * 8}px;
  `;
};
const marginRight = n => {
  return css`
    margin-right: ${n * 8}px;
  `;
};

export { marginTop, marginRight, marginBottom };
