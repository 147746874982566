import { css } from "emotion";

const containerStyles = css`
  height: 64px;
  background: #212121;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tools {
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    display: flex;

    & > * {
      display: flex;
      height: 64px;
      width: 64px;
      align-items: center;
      justify-content: center;
    }
  }
`;

const iconButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 64px;
  svg {
    fill: white;
    width: 32px;
    height: 32px;
  }
`;

export { containerStyles, iconButtonStyles };
