import { css } from "emotion";

const containerStyles = css`
  .menu {
    position: fixed;
    background: #212121;
    color: white;
    border-top: 1px solid white;
    padding: 10px 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: transform 0.5s;
    transform: translate3d(0, 400px, 0);

    &.visible {
      transform: translate3d(0, 0, 0);
    }

    & > div {
      padding: 0px 16px;
      line-height: 36px;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;
    }
  }

  .cancelButton,
  .confirmButton {
    color: white;
    border-radius: 2px;
    padding: 4px 8px;
    margin: 0 8px;
  }

  .confirmButton {
    background: green;
  }

  .cancelButton {
    background: red;
  }

  .overlay {
    background: #000;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.2s;
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100%;
    visibility: hidden;

    &.display {
      opacity: 0.3;
      visibility: visible;
    }
  }
`;

export { containerStyles };
