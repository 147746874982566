import React, { useState, useCallback, useRef, useEffect } from "react";
import { containerStyles } from "./ItemForm.style";
import { MdTimer } from "react-icons/md";
import { toolbarStyle } from "shared/styles/toolbar";
import { withRouter } from "react-router-dom";

const durationPeriod = ["Minutes", "Hours"];

function renderDurationPeriodOption(duration) {
  return <option value={duration}>{duration}</option>;
}

function ItemForm({ onSubmit, item, category, history }) {
  const [title, setTitle] = useState(item.title);
  const [hasDuration, setHasDuration] = useState(item.duration);
  const [durationValue, setDurationValue] = useState(
    item.duration ? item.duration.durationValue : 15
  );
  const [durationUnit, setDurationUnit] = useState(
    item.duration ? item.duration.durationUnit : "Minutes"
  );

  const [description, setDescription] = useState(
    `${item.link ? `${item.link}\n` : ""}${item.description || ""}`
  );
  const handleSubmit = useCallback(
    function handleSubmit(e) {
      e.preventDefault();
      onSubmit({
        title,
        description,
        duration: hasDuration ? { durationValue, durationUnit } : null
      });
    },
    [onSubmit, title, description, hasDuration, durationValue, durationUnit]
  );

  const handleCancel = useCallback(
    function handleCancel(e) {
      history.push(`/items/${item.id}`);
    },
    [history, item.id]
  );

  const handleTitleInputChange = useCallback(
    function handleTitleInputChange(e) {
      setTitle(e.target.value);
    },
    [setTitle]
  );

  const toggleHasDuration = useCallback(
    function toggleHasDuration() {
      setHasDuration(!hasDuration);
    },
    [setHasDuration, hasDuration]
  );

  const handleDurationValueChange = useCallback(
    function handleDurationValueChange(e) {
      setDurationValue(e.target.value);
    },
    [setDurationValue]
  );
  const handleDurationUnitChange = useCallback(
    function handleDurationUnitChange(e) {
      setDurationUnit(e.target.value);
    },
    [setDurationUnit]
  );
  const handleDescriptionTextAreaChange = useCallback(
    function handleDescriptionTextAreaChange(e) {
      setDescription(e.target.value);
    },
    [setDescription]
  );

  const titleInputRef = useRef();
  useEffect(() => {
    const titleInputElement = titleInputRef.current;
    if (titleInputElement) {
      titleInputElement.focus();
    }
  }, []);

  return (
    <form
      data-testid="item-form"
      className={containerStyles({
        categoryColor: category.color,
        hasDuration
      })}
      onSubmit={handleSubmit}
    >
      <div className={toolbarStyle({ edit: true })}>
        <div className={"actions"}>
          <button className="cancel" type="button" onClick={handleCancel}>
            Cancel
          </button>
          <button className="submit" type="submit">
            Save
          </button>
        </div>
      </div>
      <div className="inputs">
        <input
          ref={titleInputRef}
          value={title}
          onChange={handleTitleInputChange}
          required={true}
          name="title"
          placeholder="Add a Title"
        />
        <textarea
          value={description ? description : ""}
          onChange={handleDescriptionTextAreaChange}
          name="description"
          placeholder="Add a Description"
        />
        <div className="duration">
          <MdTimer onClick={toggleHasDuration} size={32} />
          {hasDuration && (
            <>
              <input
                onChange={handleDurationValueChange}
                value={durationValue}
                type="number"
              />
              <select onChange={handleDurationUnitChange} value={durationUnit}>
                {durationPeriod.map(renderDurationPeriodOption)}
              </select>
            </>
          )}
        </div>
      </div>
    </form>
  );
}

export default withRouter(ItemForm);
