import React, { useCallback } from "react";
import { connect } from "react-redux";
import { getCategory } from "reducers";
import { updateCategory } from "categories/actions";
import { CategoryForm } from "categories/components/CategoryForm";

function EditCategory({
  updateCategory,
  history,
  category,
  match: {
    params: { categoryId }
  }
}) {
  const handleSubmit = useCallback(
    function handleSubmit(category) {
      updateCategory(categoryId, category);
      history.push(`/categories/${categoryId}`);
    },
    [updateCategory, history, categoryId]
  );

  return (
    <div>
      <CategoryForm onSubmit={handleSubmit} category={category} />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const {
    match: {
      params: { categoryId }
    }
  } = ownProps;
  return {
    category: getCategory(state, categoryId)
  };
}

export default connect(
  mapStateToProps,
  { updateCategory }
)(EditCategory);
