import { css } from "emotion";

const sidebarContainerStyles = css`
  .sidebar {
    position: fixed;
    top: 0;
    background: #212121;
    box-shadow: 0px 0px 10px #000;
    height: 100%;
    width: 256px;
    transform: translateX(-260px);
    transition-property: transform;
    transition-duration: 0.2s;
    z-index: 2;

    &.open {
      transform: translateX(0);
    }

    & > div {
      cursor: pointer;
      user-select: none;
    }
  }

  .app-title {
    display: flex;
    align-items: center;
    font-size: 24px;
    height: 60px;
  }

  .overlay {
    background: #000;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.2s;
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100%;
    visibility: hidden;

    &.display {
      opacity: 0.3;
      visibility: visible;
    }
  }
`;

const sidebarMenuItemStyles = css`
  height: 64px;
  padding: 4px 16px;
  color: white;
  line-height: 60px;
  font-size: 1.2em;
  font-weight: 300;

  &.active {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const mainLogoStyles = css`
  display: inline-block;
  margin-right: 16px;
  margin-left: -2px;
  width: 36px;
  fill: white;

  & + span {
    margin-top: 3px;
    line-height: initial;
  }
`;

export { sidebarContainerStyles, sidebarMenuItemStyles, mainLogoStyles };
